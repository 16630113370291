import React, { useEffect, useState, useContext, userEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserContext, SnackbarContext } from "../../contexts";
import { useInput, usePagination } from "../../hooks";
import API from "../../api";
import Util from "../../util";
import {
  FormInput,
  Button,
  Paging,
  Board,
  TablePayment,
} from "../../components";
import { useTranslation } from "react-i18next";

export const MypageMembershipPage = (props) => {
  const { t } = useTranslation(); // useTranslation 훅을 사용하여 t 함수를 가져옴

  const history = useHistory();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { userInfo, refreshUserInfo } = useContext(UserContext);
  const [list, setList] = useState(null);
  const [
    page,
    totalPage,
    totalCount,
    onPageChange,
    setPage,
    setTotalPage,
    setTotalCount,
  ] = usePagination(1, 10);
  const [loading, setLoading] = useState(false);

  console.log("userInfo", userInfo);

  useEffect(() => {
    refreshUserInfo();
  }, []);

  useEffect(() => {
    requsetList();
  }, [page]);

  const requsetList = (init_page) => {
    setLoading(true);
    let params = {
      page: init_page ? init_page : page,
      limit: 10,
    };

    API.Member.GetPayment(params).then(
      (res) => {
        let result = res.data;
        console.log("Member.GetPayment", result);
        setList(result.items);
        setTotalPage(result.meta.totalPages);
        setTotalCount(result.meta.totalCount);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        updateSnackbar({
          type: "error",
          open: true,
          message: err.data.message,
        });
      }
    );
  };

  if (!userInfo) {
    return null;
  }

  return (
    <article className="mypage-membership-page">
      <section className="title__box">
        <h3>{t("mypage.membership_status.title")}</h3>
      </section>
      <section className="membership-status__box">
        <ul>
          <li>
            <header>
              <span>{t("mypage.membership_status.kr_label")}: </span>
            </header>
            <div className="value__box">
              <span>
                {userInfo.vipDate
                  ? Util.Form.getYYYYMMDDByUnix(userInfo.vipDate)
                  : "-"}
              </span>
            </div>
          </li>
          <li>
            <header>
              <span>{t("mypage.membership_status.en_label")} : </span>
            </header>
            <div className="value__box">
              <span>
                {userInfo.vipDateUsa
                  ? Util.Form.getYYYYMMDDByUnix(userInfo.vipDateUsa)
                  : "-"}
              </span>
            </div>
          </li>
          <li>
            <header>
              <span>{t("mypage.membership_status.vnm_label")} : </span>
            </header>
            <div className="value__box">
              <span>
                {userInfo.vipDateVnm
                  ? Util.Form.getYYYYMMDDByUnix(userInfo.vipDateVnm)
                  : "-"}
              </span>
            </div>
          </li>
          <li>
            <header>
              <span>{t("mypage.membership_status.all_label")} : </span>
            </header>
            <div className="value__box">
              <span>
                {userInfo.vipDateAll
                  ? Util.Form.getYYYYMMDDByUnix(userInfo.vipDateAll)
                  : "-"}
              </span>
            </div>
          </li>
        </ul>
      </section>
      <section className="title__box">
        <h3>{t("mypage.membership_payment.title")}</h3>
      </section>
      <section className="table__box">
        <TablePayment data={list} loading={loading}></TablePayment>
      </section>

      <section className="page__box">
        <Paging
          page={page}
          totalPage={totalPage}
          onChange={onPageChange}
        ></Paging>
      </section>
    </article>
  );
};
